

.dropZoneWrapper,
.dropZoneWrapper > div {
    width: 100%;
    display: flex;
    border: dashed;
    max-width: 800px;
    max-height: 250px;
    border-color: #C8C8C8;
    background-color: #F0F0F0;
}

.dropZoneWrapper {
    border: 1px dashed #fff;
}

.dropZoneWrapper > div {
    flex-direction: column;
    text-align: center;
}

.dropZoneActive {
    background: hsl(210, 100%, 93%);
    border-color: HSL(210, 100%, 73%);
}

.footer {
    font-size: 12px;
    color: #949494;
}
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: grid;
  place-items: center;
  z-index: 1000;
}

.modalContent {
  background: #fff;
  max-width: 700px; /* Limite máximo de largura */
  width: 90vw; /* Responsivo para telas menores */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0;
  min-height: 200px; /* Altura mínima */
  max-height: 90vh; /* Altura máxima, ajustável */
}

.modalBody {
  overflow-y: auto;
  padding: 20px;
}

.modalFooter {
  padding: 10px 20px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #ddd;
  background-color: #f5f5f5;
}

table {
    background: #fff;
    width: 100%;
    border-radius: 3px;
    margin: 0 0 18px;
    border: 1px solid #ddd;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
}

table thead, table tfoot {
    background: #f5f5f5;
}

table thead tr th, table tfoot tr th, table tbody tr td, table tr td, table tfoot tr td {
    font-size: 12px;
    line-height: 18px;
    text-align: left;
}

table thead tr th, table tfoot tr td {
    padding: 8px 10px 9px;
    font-size: 14px;
    font-weight: bold;
    color: #222;
}

table thead tr th:first-child, table tfoot tr td:first-child {
    border-left: none;
}

table thead tr th:last-child,  table tfoot tr td:last-child {
    border-right: none;
}

table tbody tr.even, table tbody tr.alt {
    background: #f9f9f9;
}

table tbody tr:nth-child(even) {
    background: #f9f9f9;
}

table tbody tr td {
    color: #333;
    padding: 9px 10px;
    vertical-align: top;
    border: none;
}

.panel-heading {
    width: 100%;
    height: 41px;
    background-color: #C7C1C1;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.panel-heading > p {
    font-size: 14px;
    padding: 10px 0 0 10px;
}

.panel-body {
    position: relative;
    border: 1px solid #C7C1C1;
    color: grey;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top: none;
    height: 150px;
}

.label-cliente-container {
    display: flex;
    margin-bottom: 10px;
    font-size: 12px;
}

.tipo-cliente {
    padding: 20px 0 0 15px;
}

.tipo-cliente > select {
    width: 100%;
    min-width: 500px;
    padding: 8px;
    border: 1px solid #C7C1C1;
    border-radius: 3px;
    background-color: #F5F5F5;
    transition: all 0.3s ease-in-out;
}

.mes-aniversario {
    display: flex;
    flex-direction: column;
    padding: 20px 0 0 15px;
}

.mes-aniversario > select {
    width: 100%;
    min-width: 200px;
    padding: 8px;
    border: 1px solid #C7C1C1;
    border-radius: 3px;
    background-color: #F5F5F5;
}

.botoes {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.consulta-individual {
    display: flex;
    margin-top: 20px;
    align-items: center;
}

.consulta-individual > input {
    height: 20px;
    width: 20px;
    cursor: pointer;
}

.nome-aniversariante-container > input {
    width: 100%;
    min-width: 500px;
    padding: 8px;
    border: 1px solid #C7C1C1;
    border-radius: 3px;
    background-color: #F5F5F5;
}

/* CSS para transição de fade */
.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in-out;
}

.fade-exit {
    opacity: 1;
}


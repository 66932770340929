.ql-editor {
    font-size: 14px;
    line-height: 1.6;
    font-family: Helvetica, Arial, sans-serif;
    color: #4D4D4D;
  }
  

@media (min-width: 2560px) {
  .textfields {
    display: flex;
    justify-content: center;
  }
}
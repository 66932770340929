/* src/components/MatrixNineBox.css */

.matrix-nine-box {
    position: relative;
  }
  
  /* Container da matriz incluindo os labels */
  .matrix-container {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr auto;
    gap: 10px;
    align-items: start; /* Alinha os itens ao topo */
    max-width: 1200px; /* Centraliza o conteúdo */
    margin: 0 auto;
  }
  
  /* Texto da esquerda */
  .left-labels {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Pode ser start, center, space-between */
    
  }
  
  .left-labels div {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    text-align: center;
    font-weight: bold;
    height: 275px /* Altura total dos 3 boxes mais os gaps */
  }
  
  /* Matriz original */
  .matrix-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(0, 1fr);
    gap: 3px;
    /* Remover max-width e margin para evitar centralização dupla */
    /* max-width: 1200px; */
    /* margin: 0 auto; */
  }
  
  /* Texto inferior */
  .bottom-labels {
    grid-column: 1 / span 2; /* Faz o label inferior ocupar as duas colunas */
    display: flex;
    justify-content: space-between;
  }
  
  .bottom-labels div {
    width: calc(33.33% - 6.66px);
    text-align: center;
    font-weight: bold;
    margin-top: -60px;
  }
  
  /* Estilos dos boxes (mantidos) */
  
  .box {
    padding: 10px;
    color: #212529;
    height: 250px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Estilos específicos para cada box */
  
  .box.box-1 {
    background-color: #f0717b; /* Vermelho claro */
  }
  
  .box.box-2 {
    background-color: #e7d390; /* Laranja claro */
  }
  
  .box.box-3 {
    background-color: #e7d390; /* Amarelo claro */
  }
  
  .box.box-4 {
    background-color: #04aecc; /* Azul claro */
  }
  
  .box.box-5 {
    background-color: #04aecc; /* Verde claro */
  }
  
  .box.box-6 {
    background-color: #04aecc; /* Azul muito claro */
  }
  
  .box.box-7 {
    background-color: #81f5ae; /* Cinza claro */
  }
  
  .box.box-8 {
    background-color: #81f5ae; /* Azul esverdeado claro */
  }
  
  .box.box-9 {
    background-color: #0f9757; /* Verde mais claro */
  }
  
  .box h3 {
    margin-top: 0;
    font-size: 16px;
    color: #212529;
    flex-shrink: 0;
  }
  
  .photos {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    overflow-y: auto;
  }
  
  .photo {
    width: calc(20% - 10px); /* Ajustado para 5 fotos por linha */
    margin: 5px;
    position: relative;
  }
  
  .photo img {
    width: 100%;
    border-radius: 50%;
    transition: transform 0.2s;
  }
  
  .photo img:hover {
    transform: scale(1.15);
  }
  
  .see-more {
    border-radius: 10px;
    margin-top: auto;
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    flex-shrink: 0;
  }
  
  .see-more:hover {
    background-color: #0056b3;
  }
  
  /* Popup e demais estilos existentes */
  
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex; /* Adicionado */
    justify-content: center; /* Adicionado */
    align-items: center; /* Adicionado */
  }
  
  .popup-content {
    background-color: #fff;
    padding: 20px;
    width: 50%;
    max-height: 80%; /* Limita a altura máxima */
    overflow-y: auto; /* Adiciona rolagem vertical se necessário */
    border-radius: 5px;
    z-index: 1001;
}
  
  .popup-content h2 {
    margin-top: 0;
  }
  
  .popup-content ul {
    list-style: none;
    padding: 0;
  }
  
  .popup-content li {
    margin-bottom: 5px;
  }
  
  .popup-content button {
    background-color: #dc3545;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .popup-content button:hover {
    background-color: #c82333;
  }
  
  /* Classe para desabilitar a rolagem quando o popup está aberto */
  .no-scroll {
    overflow: hidden;
  }
